const baseUrl = window.location.origin;
const API_URL = "https://hub.coderslab.si/api";

export default {
  API_URL,
  baseUrl,
  googleClientId:
    "860288514084-3nksna863uipq9ptmsbdmi70s0tkdqin.apps.googleusercontent.com",
  googleClientSecret: "dxq8uu9-KEbqBySbkPoDbSo6",
  sentryDsn: "https://3ad161332e14cc3a158e5e27d2fb0011@o203382.ingest.sentry.io/4505793786937344",
  selectedLang: "en",
};
